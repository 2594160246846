/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Img from 'gatsby-image'

// CSS
import './ErvaringPreviewBig.scss'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components 
import { ButtonLineRound } from 'components/Elements'

// Third Party
import parse from 'html-react-parser'

const ErvaringPreviews = ({ ervaring }) => {
  return (
    <div className="big-ervaring-preview pt-5">
      <div className="container" />
      <div className="color-background-light-grey pt-3 pb-5">
        <div className="container">
          <h2 className="mt-4 mb-5 font-size-xl font-weight-xl color-text-contrast">ERVARING VAN ONZE KLANT</h2>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Img className="big-ervaring-preview-image" fluid={ervaring.acf.preview.image.localFile.childImageSharp.fluid} />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <h3 className="font-size-l py-3 py-lg-0 font-weight-xl">{`"${ervaring.acf.preview.quote}"`}</h3>
                  {parse(ervaring.acf.preview.small_introduction)}
                </div>
                <div className="col-12 py-3">
                  <ButtonLineRound className="float-sm-right float-middle color-background-light px-3 px-lg-5" to={`/ervaringen/${ervaring.slug}`}>
                    Lees meer over dit succes!
                    <img className="pl-2 m-0" alt="" src={orangeArrow} />
                  </ButtonLineRound>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErvaringPreviews
