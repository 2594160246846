/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { graphql, navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Products from 'components/Products'
import BrandSlider from 'components/Brands/BrandSlider'
import {
  ButtonAlt,
  BannerCustom,
  ProductBanner,
} from 'components/Elements/Elements'
import Specialist from 'components/Specialist'
import ErvaringPreviewBig from 'components/Ervaringen/ErvaringPreviewBig'
import CustomLink from 'components/CustomLink'

// CSS
import 'styles/Product.scss'

// Images
import whiteArrow from 'img/white_arrow.svg'
import orangeCheck from 'img/orange_check.svg'

// Third Party
import _ from 'lodash'
import parse from 'html-react-parser'

export const ProductTemplate = ({
  slug,
  name,
  slugType,
  producten,
  bannerImage,
  bigBanner: {
    banner_image: {
      localFile: {
        childImageSharp: { fluid: bigBannerImage },
      },
    },
    banner_text: bigBannerText,
    banner_button_text: bigBannerButtonText,
  },
  blok1: {
    title: blok1title,
    wordpress_3_points: { punt_1: point1, punt_2: point2, punt_3: point3 },
  },
  content1,
  banner2,
  specialist,
  ervaring,
  action,
  merken,
}) => {
  let safariBool = false

  if (typeof window !== 'undefined') {
    if (
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    ) {
      safariBool = true
    } else {
      safariBool = false
    }
  }

  const handleChange = (e) => {
    navigate(`/producten/${slug}/${e.target.value}/#a`)
  }

  return (
    <>
      <section className="section product">
        <div className="product-top">
          <div className="row position-relative">
            <div className="image-container col-12">
              <BackgroundImage fluid={bannerImage} />
              <div className="grey-empty-banner" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex">
                  <div className="image-text-container d-flex flex-wrap w-100 pt-lg-4 pt-0">
                    <h1 className="font-size-xl uppercase pr-3 mb-4 font-weight-xl color-text-light m-0">
                      {name}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container container-products">
              <div className="product-row mt-3 mb-4 w-100 d-none d-lg-flex justify-content-between">
                <Products row />
              </div>
            </div>
          </div>
        </div>
        <div id="a" />
        <div>
          <div className="product-mobile-top container pt-4 d-flex flex-wrap d-lg-none justify-content-center mb-3 w-100">
            <CustomLink
              to="/contact"
              className="product-contact-button w-100 px-3 mb-3 py-4 color-background-contrast d-flex justify-content-center align-items-center ctabanner-button"
            >
              <p className="color-text-light font-weight-xl mb-0 text-uppercase">
                {bigBannerButtonText}
              </p>
              <img className="pl-2" alt="arrow" src={whiteArrow} />
            </CustomLink>
            <label className="product-mobile-top-selectcontainer w-100">
              <select
                onChange={handleChange}
                className="product-mobile-top-select w-100 pl-3 color-text-main mb-3 py-3"
              >
                {producten.map((product) => (
                  <option
                    selected={product.post_name === slugType}
                    value={product.post_name}
                    className="product-mobile-top-option"
                  >
                    {parse(product.post_title)}
                  </option>
                ))}
              </select>
            </label>
            <Img
              className="product-mobile-top-image"
              fluid={bigBannerImage}
              alt=""
            />
          </div>
        </div>
        <div
          className={`${safariBool ? `safari` : `not-safari`
            } big-image-container d-lg-block d-none mt-2 mt-lg-5 pt-3 pb-5 position-relative`}
        >
          <BackgroundImage fluid={bigBannerImage} alt="" />
          <div className="position-absolute m-y-minus-per-10 z-20 ab-25 d-lg-flex d-none flex-wrap text-center justify-content-center w-100">
            <h2 className="font-size-xl w-50 font-weight-xl color-text-light">
              {bigBannerText}
            </h2>
            <div className="pt-4 w-100">
              <ButtonAlt to="/showroomafspraak-maken/">
                {bigBannerButtonText}
                <img className="pl-2" alt="arrow" src={whiteArrow} />
              </ButtonAlt>
            </div>
          </div>
          <div className="gradient position-absolute w-100 h-100" />
        </div>
        <ProductBanner
          slug={slug}
          producten={producten}
          className="color-background-secondary d-lg-flex d-none mt-2 mt-lg-5"
        />

        <div className="container d-lg-block d-none py-5">
          <h2 className="font-size-xl pb-4 font-weight-xl color-text-contrast">
            {blok1title}
          </h2>
          <div className="row">
            <p className="col-12 col-lg-4 d-flex align-items-center">
              <img className="mr-3" alt="" src={orangeCheck} />
              {point1}
            </p>
            <p className="col-12 col-lg-4 d-flex align-items-center">
              <img className="mr-3" alt="" src={orangeCheck} />
              {point2}
            </p>
            <p className="col-12 col-lg-4 d-flex align-items-center">
              <img className="mr-3" alt="" src={orangeCheck} />
              {point3}
            </p>
          </div>
        </div>
        <div className="container content-container">
          <div className="row">
            <div className="col-12 col-lg-8">
              {parse(content1, {
                replace: (domNode) => {
                  if (domNode.name === 'a') {
                    return (
                      <CustomLink to={domNode.attribs.href}>
                        {domNode.children[0].data}
                      </CustomLink>
                    )
                  }
                  return true
                },
              })}
            </div>
            <div className="container d-block d-lg-none py-5">
              <h2 className="font-size-xl pb-4 font-weight-xl color-text-contrast">
                {blok1title}
              </h2>
              <div className="row">
                <p className="col-12 col-lg-4 d-flex align-items-center">
                  <img className="mr-3" alt="" src={orangeCheck} />
                  {point1}
                </p>
                <p className="col-12 col-lg-4 d-flex align-items-center">
                  <img className="mr-3" alt="" src={orangeCheck} />
                  {point2}
                </p>
                <p className="col-12 col-lg-4 d-flex align-items-center">
                  <img className="mr-3" alt="" src={orangeCheck} />
                  {point3}
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4 d-flex flex-wrap justify-content-center justify-content-lg-end">
              <Specialist specialist={specialist} />
              {action && action.image && (
                <a href={action.link} target="_blank">
                  <img
                    src={action.image.localFile.publicURL}
                    width="282"
                    height="564"
                    className="mt-5"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="empty-space-50" />
        <div className="container d-block d-lg-none">
          <Products title="Bekijk meer producten" />
        </div>
        <ErvaringPreviewBig ervaring={ervaring} />
        <BannerCustom
          to="/contact"
          className={`color-background-main ${merken.length === 0 && `mb-4`}`}
          bannerText={banner2.text}
          bannerButtonText={banner2.button_text}
        />
        {merken.length >= 1 && (
          <BrandSlider
            title={`Onze Merken ${name}`}
            merken={merken}
            className="py-5"
          />
        )}
      </section>
    </>
  )
}

const Product = ({ data }) => {
  const { wordpressWpProduct: product, allWordpressWpProduct: otherProducten } =
    data

  const producten = []
  _.each(
    _.filter(
      otherProducten.nodes,
      (o) => o.soorten[0].slug === product.soorten[0].slug
    ),
    (p) => {
      producten.push({ post_title: p.title, post_name: p.slug })
    }
  )

  return (
    <Layout noPhone>
      <SEO yoast={{ meta: product.yoast_meta }} />
      <ProductTemplate
        slug={product.soorten[0].slug}
        name={product.soorten[0].name}
        slugType={product.slug}
        slugName={product.title}
        producten={producten}
        bannerImage={product.acf.banner_image.localFile.childImageSharp.fluid}
        bigBanner={product.acf.big_banner}
        blok1={product.acf.blok_1}
        content1={product.acf.content_1}
        banner2={product.acf.banner_2}
        specialist={product.acf.specialist}
        ervaring={product.acf.relation[0]}
        merken={product.merken}
        action={product.acf.action}
      />
    </Layout>
  )
}

export default Product

export const pageQuery = graphql`
  fragment ProductPostFields on wordpress__wp_product {
    id
    slug
  }
  query DetailProductQuery($id: String!) {
    wordpressWpProduct(id: { eq: $id }) {
      slug
      title
      soorten {
        slug
        name
      }
      merken {
        post_name
        acf {
          brand_image {
            localFile {
              publicURL
            }
          }
        }
      }
      acf {
        content_1
        blok_1 {
          title
          wordpress_3_points {
            punt_1
            punt_2
            punt_3
          }
        }
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        big_banner {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          banner_text
          banner_button_text
        }
        banner_2 {
          button_text
          text
        }
        specialist {
          title
          slug
          acf {
            number
            specialist_text
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        relation {
          acf {
            preview {
              quote
              small_introduction
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          slug
        }
        action {
          image {
            localFile {
              publicURL
            }
          }
          link
        }
      }
      yoast_meta {
        content
        name
        property
      }
    }
    allWordpressWpProduct {
      nodes {
        title
        slug
        soorten {
          slug
        }
      }
    }
  }
`
